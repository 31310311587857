<template>
    <div style="width: 100%" class="kits-shift-change">
        <div class="title">
            <img
                class="transfer-icon"
                :src="transferIcon"
                alt="走读申请图标"
            />
            <span class="label">走读申请套件</span>
        </div>
        <el-form-item label="申请人" required>
            <el-input
                style="width: 100%"
                placeholder="请选择申请人"
                v-model="form.applicant"
            ></el-input>
        </el-form-item>
        <el-form-item label="学生类型" required>
            <el-select style="width: 100%" v-model="form.studentType" placeholder="请选择走读学生类型">
                <el-option
                    v-for="item in studentTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                ></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="开始日期" required>
            <el-date-picker
                style="width: 100%"
                v-model="form.dayStudentStartDate"
                type="date"
                placeholder="开始日期">
            </el-date-picker>
        </el-form-item>
        <el-form-item label="结束日期" required>
            <el-date-picker
                style="width: 100%"
                v-model="form.dayStudentEndDate"
                type="date"
                placeholder="结束日期">
            </el-date-picker>
        </el-form-item>
        <el-form-item label="申请原因" :required="reasonRequiredType" >
            <el-input
                style="width: 100%"
                type="textarea"
                :rows="4"
                placeholder="请输入申请原因"
                v-model="form.dayStudentReason"
                maxlength="200"
                show-word-limit
            ></el-input>
        </el-form-item>
        <el-form-item label="附件上传" :required="attachmentRequiredType">
            <el-upload
                class="upload-demo"
                :action="''"
                accept=".jpg, .jpeg, .png, .pdf , .doc ,.docx ,.xls"
                >
                <el-button size="small" type="primary" icon="el-icon-plus">上传附件</el-button>
                </el-upload>
        </el-form-item>
        <el-form-item >
            <el-input
                style="width: 100%"
                type="textarea"
                :rows="3"
                placeholder="请输入说明文字"
                maxlength="300"
                show-word-limit
                :value="dayStudentComment"
                @input="changeDayStudentCommentContent"
            ></el-input>
        </el-form-item>
    </div>
</template>

<script>
const kitName = 'kitsDayStudent'
export default {
    name: 'KitsDayStudent',
    components: {},
    props: {
        reasonRequired: {
            type: Number,
            default: () => {
                return 0
            }
        },
        attachmentRequired: {
            type: Number,
            default: () => {
                return 0
            }
        },
        dayStudentComment: {
            type: String,
            default: () => {
                return '审批通过后，在开始和结束时间内，申请人的学生类型会变为表单中的学生类型'
            }
        },
    },
    data() {
        return {
            studentTypeList:[
                {
                    value:0,
                    label:'住宿'
                },{
                    value:1,
                    label:'走读'
                },{
                    value:2,
                    label:'半读'
                }
            ],
            reasonRequire:true,
            dayStudentCommentContent:this.dayStudentComment,
            attachmentRequire:false,
            form: {
                applicant:'',
                studentType:'',
                dayStudentStartDate:'',
                dayStudentEndDate:'',
                dayStudentReason:'',
                descriptionText:''
            },
            shiftWorkerList: [],
        }
    },
    filters: {},
    computed: {
        transferIcon() {
            return require("./img/transfer-icon.png");
        },
        reasonRequiredType() {
            return this.reasonRequired === 0 ? false : true;
        },
        attachmentRequiredType() {
            return this.attachmentRequired === 0 ? false : true;
        },
        // dayStudentCommentContent:{
        //     get(){
        //         return this.dayStudentComment
        //     },
        //     set(value){
        //         this.$emit("input",value)
        //     }
        // },
        isAllowMultipleApplicantType() {
            return this.isAllowMultipleApplicant === 0 ? false : true;
        },
        abstaractList() {
            const _this = this;
            let abstaractList = [
                {
                    label:'申请人',
                    id:'applicant',
                    required:true,
                    isBelongKit:true,
                    kitKey:kitName
                },
                {
                    label:'学生类型',
                    id:'studentType',
                    required:true,
                    isBelongKit:true,
                    kitKey:kitName
                },
                {
                    label:'开始日期',
                    id:'dayStudentStartDate',
                    required:true,
                    isBelongKit:true,
                    kitKey:kitName
                },
                {
                    label:'结束日期',
                    id:'dayStudentEndDate',
                    required:true,
                    isBelongKit:true,
                    kitKey:kitName
                },
                {
                    label:'申请原因',
                    id:'dayStudentReason',
                    required: this.reasonRequired === 0 ? false : true,
                    isBelongKit:true,
                    kitKey:kitName
                },

            ]
            return abstaractList;
        }
    },
    watch: {},
    created() {
    },
    mounted() {
        this.sendAbstract();
    },
    methods: {
        sendAbstract() {
            this.$eventDispatch('handlerKitAbstract', this.abstaractList)
        },
        changeDayStudentCommentContent(e){
            console.log('shifou11111',e)
            this.$eventDispatch("changeDayStudentCommentContent",e)
        },
    }
}
</script>

<style lang="scss" scoped>
.title {
    box-sizing: border-box;
    padding: 0 20px;
    display: flex;
    align-items: center;
    .transfer-icon {
        width: 11px;
        height: 11px;
        margin-right: 6px;
        display: inline-block;
    }
    .label {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #2b2f33;
    }
}

.el-form-item {
    padding-right: 0 !important;
}
</style>
