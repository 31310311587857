<template>
    <div style="width: 100%" class="kits-shift-change">
        <div class="title">
            <img
                class="transfer-icon"
                :src="transferIcon"
                alt="换班图标"
            />
            <span class="label">换班套件</span>
        </div>

        <el-form-item label="换班日期" required>
            <el-date-picker
                style="width: 100%"
                type="dates"
                v-model="form.shiftChangeDate"
                placeholder="请选择换班日期"
            ></el-date-picker>
        </el-form-item>

        <el-form-item label="换班人" required>
            <el-select style="width: 100%" v-model="form.shiftWorker" placeholder="请选择换班人">
                <el-option
                    v-for="item in shiftWorkerList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                ></el-option>
            </el-select>
        </el-form-item>

        <el-form-item label="换班原因" required>
            <el-input
                style="width: 100%"
                type="textarea"
                :rows="2"
                placeholder="请输入换班原因"
                v-model="form.shiftChangeReason"
            ></el-input>
        </el-form-item>

        <el-form-item label="宿舍信息">
            <el-input
                style="width: 100%"
                type="textarea"
                autosize
                readonly
                v-model="form.dormInfo"
            ></el-input>
        </el-form-item>
    </div>
</template>

<script>
const kitName = 'kitsShiftChange'
export default {
    name: 'KitsShiftChange',
    components: {},
    props: {},
    data() {
        return {
            form: {
                shiftChangeDate: "",
                shiftWorker: "",
                shiftChangeReason: "",
                dormInfo: ""
            },
            shiftWorkerList: []
        }
    },
    filters: {},
    computed: {
        transferIcon() {
            return require("./img/transfer-icon.png");
        },
        abstaractList() {
            const _this = this;
            let abstaractList = [
                {
                    label:'换班日期',
                    id:'shiftChangeDate',
                    required:true,
                    isBelongKit:true,
                    kitKey:kitName
                },
                {
                    label:'换班人',
                    id:'shiftWorker',
                    required:true,
                    isBelongKit:true,
                    kitKey:kitName
                },
                {
                    label:'换班原因',
                    id:'shiftChangeReason',
                    required:true,
                    isBelongKit:true,
                    kitKey:kitName
                },

            ]

            return abstaractList;
        }
    },
    watch: {},
    created() {
    },
    mounted() {
        this.sendAbstract();
    },
    methods: {
        sendAbstract() {
            this.$eventDispatch('handlerKitAbstract', this.abstaractList)
        }
    }
}
</script>

<style lang="scss" scoped>
.title {
    box-sizing: border-box;
    padding: 0 20px;
    display: flex;
    align-items: center;
    .transfer-icon {
        width: 11px;
        height: 11px;
        margin-right: 6px;
        display: inline-block;
    }
    .label {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #2b2f33;
    }
}

.el-form-item {
    padding-right: 0 !important;
}
</style>
